.loginDiv{
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: black;
    color: white;
    height: 100vh;
}
.login-leftDiv{
    width: 500px;
    /* background-color: aliceblue; */
    padding-top: 20px;
}
.login-leftDiv .emaillogin .inputbox {
    border: 1px solid lightgray;
    border-radius: 4px;

}
.login-leftDiv .emaillogin input{
    color: white;
}
.login-leftDiv .pdiv{
    text-align: center;
}

.login-leftDiv .pdiv h3{
    font-weight: bold;
    text-transform: uppercase;
    color: brown;
}
.login-leftDiv .btnbox{
    margin: 20px 0;
}

.login-leftDiv .polcydiv{
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: rgb(193, 186, 186);
}
.login-leftDiv .polcydiv p a{
    text-decoration: none;
}
.mobilediv{
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: rgb(193, 186, 186);
}
.login-leftDiv .mobilediv p a{
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: brown;

}

.login-imgdiv img{
    height: 500px;
    width: 650px;
    object-fit: cover;
    border-radius: 8px;
}

