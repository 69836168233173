.headerbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: black;
    color: white;
    border-bottom: 1px solid lightgray;
}
.heading-div p{
    font-weight: bold;
    color: red;
    text-transform: uppercase;
    margin: 0;
    padding:10px 20px;
}
.profilebox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    padding:10px 20px;
}
.profilebox div a{
    color: rgb(192, 46, 46);
    text-decoration: none;
}

.profilebox div{
    padding: 0 6px;
}

.profilebox p{
    font-weight: 500;
    color: white;
    margin: 0;
}
.profilebox div button{
    background-color: black;
    border: 1px solid white;
    color: white;
    padding: 4px 10px;
    margin-left: 5px;
}
.profilebox div button:hover{
    background-color: white;
    color: black;
    border-radius: 4px;
}


@media screen and (max-width:767px) {
    .headerbox{
        padding:5px;
    }
    .heading-div p{
        padding:10px 5px;
    }
    .profilebox{
        padding:10px 5px;
    }
    .profilebox div .notmemb{
        display: none;
    }
  
}

@media screen and (min-width:768px) and (max-width:992px){
   
}