.footerMain {
    background-color: brown;
    color: white;
    padding: 30px;
    display: flex;
    justify-content: space-around;
}

.footer-list {
    margin: 0 30px;
}

.footer-listItem {
    font-size: 14px;
    margin: 10px 0;
    border-bottom: 1px solid rgb(167, 159, 159);
    padding-bottom: 10px;
}

.footer-list .fs12 {
    font-size: 20px !important;
    font-weight: bold !important;
}

.img {
    height: 20px;
    object-fit: contain;
    background-color: white;
}

.socialDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    border-top: 1px solid lightgrey;
    background-color: brown;

}

.socialIcon {
    font-size: 50px;
    margin-left: 20px;
    color: aliceblue;
}

.downloadIcon img {
    height: 40px;
    margin-left: 20px;
}

.cpyright {
    display: flex;
    justify-content: center;
    background-color: brown;
    border-top: 1px solid lightgrey;
    color: white;
    padding-top: 20px;
}
.cpyright p{
    font-size: 14px;
    padding-left: 40px;
}

@media screen and (max-width:767px) {
    .footerMain {
        flex-wrap: wrap;
        padding: 30px 10px;

    }

    .footer-list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 10px;


    }

    .footer-listItem {
        border-bottom: unset;
        width: 190px;
        margin: 5px 0;
        padding-bottom: 0px;
    }
    .footer-list .fs12 {
        font-size: 16px !important;
        font-weight: bold !important;
    }
    .footer-listLast{
        justify-content: flex-start;
        margin-left: 5% !important;
    }
    .socialDiv {
        display: block;
        justify-content: unset;
        padding: 20px 10px 20px 50px;
    
    }
    .downloadIcon{
        margin-top: 20px;
    }
    .cpyright p{
        font-size: 12px;
        padding-left: 0px;
    }

}