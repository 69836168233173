.item img{
    height: 550px;
    width: 100%;
    object-fit: cover;
}
.swiper-pagination-bullet{
    width: 30px !important;
    height: 6px !important;
    border-radius: 4px !important;
}
.mainDiv{
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
}
.infoDiv{
    display: flex;
    align-items: center;
}
.infoDiv .iconDiv{
    padding: 10px;
    background-color: white;
    border: 2px solid gray;
    border-radius: 6px;
}
.infoDiv .iconDiv .icon{
    width: 50px;
    height: 50px;
    color: gray;
}
.infoDiv .infoTextDiv{
    padding: 10px;
    margin-left: 10px;

}
.infoDiv .infoTextDiv p{
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: gray;

}
.infoDiv .infoTextDiv h4{
    margin: 0;
    font-size: 26px;
    font-weight: bold;
}
.steps{
    background-color: brown;
    color: white;
    padding:10px;
}
.stepmainDiv{
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
}
.stepDiv{
    width: 300px;
    text-align: center;
}
.fantasy{
    display: flex;
    justify-content: space-around;
    margin: 20px;
}
.fantasy .textfantasy{
    padding: 20px;
}
.fantasy .textfantasy h4{
    font-size: 32px;
    font-weight: bold;
    color: brown;
    margin-bottom: 20px;

}
.fantasy .textfantasy p{
    font-size: 14px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
    color: rgb(67, 64, 64);

}

.fantasy .textfantasy button{
    background-color: white;
    border: 1px solid brown;
    color: brown;
    padding: 5px 30px;
}
.fantasy div{
    padding: 20px;

}
.fantasy div img{
    border-radius: 6px;
}

.tabslist-div{
    border-bottom: 1px solid lightgray;
    padding-left: 6%;
}
.mange-tab{
    padding: 6px 10px;
    background-color:  #ffffff ;
    border: none;
    margin:20px 10px 0 10px;
    font-size: 20px;
    font-weight: 500;
    color: rgb(110, 107, 107);
    border-bottom: 2px solid white;
    text-align: start;
}

.accord-ques-div {
    width: 95%;
    margin: auto;
    padding: 30px 0;
}
.accord-ques-div .css-o4b71y-MuiAccordionSummary-content{
    margin: 0 !important;
}
.accord-ques-div .MuiPaper-root{
    background-color: rgb(241, 245, 250);
    padding-left: 0;
    padding-right: 0;
    box-shadow: unset;
    border-radius: 4px;
    margin: 20px 0;
}

.accordion-div {
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px;

}
.accord-ques {
    font-weight: 500 !important;
    font-size: 18px !important;
}
.accord-answr {
    background-color: white;
    font-size: 15px !important;
    padding:10px 20px;
    
}
.css-15v22id-MuiAccordionDetails-root {
    padding:0 !important;
}

.bigquesdiv {
    width: 95%;
    margin: auto;
    padding: 30px 0;
}
.bigquesdiv .MuiPaper-root{
    background-color: rgb(255, 255, 255);
    padding-left: 0;
    padding-right: 0;
    box-shadow: unset;
    border-radius: 4px;
    margin: 20px 0;
}
.accordion-bigdiv {
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px;

}
.bigdiv-ques {
    font-weight: 500 !important;
    font-size: 30px !important;
}
.bigdiv-answr {
    background-color: white;
    font-size: 16px;
    padding:10px 20px;
}

@media screen and (max-width:767px) {
    .item img{
        height: 200px;
        width: 100%;
        object-fit: cover;
    }
    .infoDiv{
        display: block;
    }
    .infoDiv .iconDiv{
        width: 80px;
        margin-left: 20px;
    }
    .infoDiv .infoTextDiv{
        padding: 10px;
        margin-left: 10px;
    
    }
    .infoDiv .infoTextDiv p{
        font-size: 12px;
    
    }
    .infoDiv .infoTextDiv h4{
        font-size: 14px;
    }
    .stepmainDiv{
        display: block;
        text-align: center;
        margin: 40px 0;
    }
    .stepDiv{
        width: 90%;
        text-align: center;
        margin-left: 10px;
    }
    .fantasy{
        display: block;
        margin: 10px;
    }
    .fantasy .textfantasy{
        padding:20px 0px;
    }
    .fantasy .textfantasy h4{
        font-size: 20px;
        font-weight: bold;
        color: brown;
        margin-bottom: 20px;
    
    }
    .fantasy div img{
        width: 95%;
    }
   
    .accordion-div {
        border-radius: 6px;
        padding: 2px;
    }
    .accord-ques-div {
        width: 94%;
    }
    
    .accord-answr {
        padding: 3px 10px;
        color: black;
        letter-spacing: 1px;
        text-align: justify;
        font-size: 14px !important;
    }
}