.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(169, 205, 236);
  }
  
  .not-found-container h1 {
    font-weight: bold;
    font-size: 5rem;
    color: rgb(209, 9, 9);
  }
  
  .not-found-container h4 {
    margin: 20px 0;
    color: rgb(138, 32, 32);

  }